<template>
  <cm-table-data :pheaders="headers" :pconfig="config" :pcolumns="columns"/>
</template>

<script>

export default {
  name: 'index',
  data() {
    return {
      headers: [
        {
          value: 'id',
          text: 'Код',
        },
        {
          value: 'name',
          text: 'Наименование',
        },
        {
          text: 'Действие',
          value: 'actions',
          sortable: false,
        },
      ],
      columns: [
        {
          value: 'id',
          text: 'Код',
          component: 'cmTextField',
        },
        {
          value: 'name',
          text: 'Наименование',
          component: 'cmTextField',
        },
        {
          text: 'Действие',
          value: 'actions',
          sortable: false,
        },
      ],
      config: {
        title: {
          main: 'Склады',
          create: 'Создать',
          edit: 'Редактировать',
          delete: 'Удалить',
        },
        endpoints: {
          get: {
            method: 'get',
            url: '/api/v1/wms/warehouses',
            content: false,
          },
          create: {
            method: 'post',
            url: '/api/v1/wms/warehouses',
          },
          edit: {
            method: 'put',
            url: '/api/v1/wms/warehouses',
          },
        },
        permissions: {
          create: true,
          edit: true,
          delete: false,
        },
      },
    };
  },
};
</script>

<style scoped>

</style>
